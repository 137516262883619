import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

//Outras páginas
import Home from "./pages/public/others/home";
import TesteButton from "./components/public/others/utils/ButtonTypeHidden";
import NavbarCM from "./components/public/others/NavbarCM";

import NotFound from "./pages/public/others/notFound";
import MapSite from "./pages/public/buttons/mapSite";
import Transparencia from "./pages/public/buttons/transparencia";
import SearchDatabase from "./pages/public/others/searchDatabase";
//sala empreendedor
import SalaEmpreendedor from "./pages/public/transparency/salaEmpreendedor/home";
import SalaEmpreendedorMEI from "./pages/public/transparency/salaEmpreendedor/mei";
import SalaEmpreendedorSERVICES from "./pages/public/transparency/salaEmpreendedor/servicos";
import SalaEmpreendedorFERRAMENTAS from "./pages/public/transparency/salaEmpreendedor/ferramentas";

//accessibility
import Accessibility from "./pages/public/others/accessibility";
//transparencia
import ActsAdministrative from "./pages/public/ordinances/actsAdministrative";
import ActsAdministrativeSubtype from "./pages/public/ordinances/actsAdministrativeSubtype";
import HistoryBienios from "./pages/public/ordinances/historyBienios";
import SacopAndLicitacao from "./pages/public/transparency/sacop/padrao/SingleInfor";
import ListAllSacop from "./pages/public/transparency/sacop/padrao/List";
import ListConcurso from "./pages/public/transparency/concurso/List";
import ListAllTypeContrato from "./pages/public/transparency/sacop/contratos/List";
import ContratoInfo from "./pages/public/transparency/sacop/contratos/SingleInforContrato";
import Ouvidoria from "./pages/public/transparency/ouvidoria/formMesagem";

import DiaryOfficial from "./pages/public/transparency/diary/diaryOfficial";
import Esic from "./pages/public/transparency/e-sic/e-sic";

//Noticias
import UniqueNews from "./pages/public/news/uniqueNews";
import UniqueNewsSystem from "./pages/public/news/uniqueNewsSystem";

import CreateNews from "./pages/admin/news/CreateNews";

import EditNews from "./pages/admin/news/edit";
import ShowNews from "./pages/public/news/news";
import ListNews from "./pages/public/news/list";
import OfficeDiary from "./pages/public/others/officialDiary";

//login
import Login from "./pages/admin/user/login";
import CreatedUser from "./pages/admin/user/createdUser";
//competências
import CreatedOffice from "./pages/admin/office/new";
import ShowOfficeInfo from "./pages/public/others/officeInfo";
import ListOffice from "./pages/admin/office/list";
//Banner
import NewBanner from "./pages/admin/banner/new";
import ListBanner from "./pages/admin/banner/list";
import EditBanner from "./pages/admin/banner/edit";
import RedesSociais from "./components/public/redes-sociais";

import Organograma from "./pages/public/others/organograma";

// import Loading from './components/public/others/LoadingFull'
import { useContext } from "react";
import { AuthProvicer, AuthContext } from "./contexts/auth";
import { TipoPortal, RoutesDaily } from "./services/variables";
import ListCardSacopAndFilters from "./pages/public/transparency/sacop/padrao/ListAndFilters";
import DadosAbertosPage from "./pages/public/transparency/dados-abertos";

export default function RoutesF() {
  const PrivateRoute = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);
    if (loading) {
      return;
    }
    if (!authenticated) {
      console.log("não tá logado");
      return <Navigate to="/admin/login" />;
    }

    return children;
  };

  return (
    <>
      <BrowserRouter>
        <AuthProvicer>
          <Routes>
            {/* Outras páginas */}
            <Route path="/teste" element={<DiaryOfficial />}></Route>
            <Route path="/teste/diario" element={<DiaryOfficial />}></Route>
            <Route path="/teste/esic" element={<Esic />}></Route>
            <Route
              path="/teste/button"
              element={
                <>
                  <NavbarCM></NavbarCM>
                  <TesteButton link={"/teste/1"} targetBlank={true}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      className="icon-download-DOWNLOAD"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </TesteButton>
                  {/* <TesteButton link={'/admin/banner/painel'}> <>Compenente</>
            </TesteButton> */}
                </>
              }
            ></Route>
            <Route exact path="/" element={<Home />} />
            <Route element={<RedesSociais />} exact path="/redes-sociais" />
            <Route
              element={<Organograma />}
              exact
              path="/estrutura-organizacional"
            />
            <Route
              exact
              path="/pesquisar/:wordSearch"
              element={<SearchDatabase />}
            />
            <Route exact path="/mapa-site" element={<MapSite />} />
            <Route exact path="/acessibilidade" element={<Accessibility />} />
            <Route
              exact
              path="/diario-oficial/diario-oficial-eletronico"
              element={<OfficeDiary />}
            />
            <Route exact path="/transparencia" element={<Transparencia />} />
            {/* Páginas de Detalhes */}
            <Route
              exact
              path="/detalhe/:slugURL"
              element={<UniqueNewsSystem slug="" />}
            />
            {/* Noticias  */}
            <Route exact path="/noticias" element={<ShowNews />} />
            <Route exact path="/noticia/:idNoticias" element={<UniqueNews />} />
            <Route
              exact
              path="/admin/noticias/criar"
              element={<CreateNews />}
            />
            <Route
              exact
              path="/admin/noticias/painel"
              element={
                <PrivateRoute>
                  <ListNews />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/admin/noticias/editar/:idNoticias"
              element={
                <PrivateRoute>
                  <EditNews />
                </PrivateRoute>
              }
            />{" "}
            <Route
              path="/transparencia/dados-abertos"
              element={<DadosAbertosPage />}
            />
            {/* competências */}
            <Route
              exact
              path="/admin/competencias"
              element={
                <PrivateRoute>
                  <CreatedOffice />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/admin/competencias/painel"
              element={
                <PrivateRoute>
                  <ListOffice />
                </PrivateRoute>
              }
            />
            {/* Ouvidoria */}
            <Route
              exact
              path="/ouvidoria/atendimento-presencial"
              element={
                <UniqueNewsSystem slug={"ouvidoria-atendimento-presencial"} />
              }
            />
            <Route
              exact
              path="/ouvidoria/carta-de-servicos-ao-usuario"
              element={
                <UniqueNewsSystem
                  slug={"ouvidoria-carta-de-servicos-ao-usuario"}
                />
              }
            />
            <Route
              exact
              path="/ouvidoria/redes-sociais"
              element={<UniqueNewsSystem slug={"ouvidoria-redes-sociais"} />}
            />
            <Route
              exact
              path="/ouvidoria/perguntas-e-respostas"
              element={
                <UniqueNewsSystem slug={"ouvidoria-perguntas-e-respostas"} />
              }
            />
            <Route
              exact
              path="/atendimento/fale-conosco-atendimento"
              element={<UniqueNewsSystem slug={"fale-conosco-atendimento"} />}
            />
            <Route
              exact
              path="/ouvidoria/canal-eletronico-de-acesso"
              element={
                <Ouvidoria route={"/ Ouvidoria / Canal Eletrônico de Acesso"} />
              }
            />
            <Route
              exact
              path="/ouvidoria/canal-eletronico"
              element={<Ouvidoria route={"/ Ouvidoria / Canal Eletrônico"} />}
            />
            {/* Banner */}
            <Route
              exact
              path="/admin/banner/new"
              element={
                <PrivateRoute>
                  <NewBanner />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/admin/banner/painel"
              element={
                <PrivateRoute>
                  <ListBanner />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/admin/banner/editar/:idBanner"
              element={
                <PrivateRoute>
                  <EditBanner />
                </PrivateRoute>
              }
            />
            {/* login */}
            <Route exact path="/admin/login" element={<Login />} />
            <Route
              exact
              path="/admin/login/created"
              element={<CreatedUser />}
            />
            {/* Município PM*/}
            <Route
              exact
              path="/municipio/cidade"
              element={<UniqueNewsSystem slug={"municipio-cidade"} />}
            />
            {/* Município PM e CM*/}
            <Route
              exact
              path="/municipio/distancias"
              element={<UniqueNewsSystem slug={"municipio-distancias"} />}
            />
            <Route
              exact
              path="/municipio/historia"
              element={<UniqueNewsSystem slug={"municipio-historia"} />}
            />
            <Route
              exact
              path="/municipio/populacao"
              element={<UniqueNewsSystem slug={"municipio-populacao"} />}
            />
            <Route
              exact
              path="/municipio/mapa"
              element={<UniqueNewsSystem slug={"municipio-mapa"} />}
            />
            <Route
              exact
              path="/municipio/enderecos-uteis"
              element={<UniqueNewsSystem slug={"municipio-enderecos-uteis"} />}
            />
            {/* Município CM*/}
            <Route
              exact
              path="/municipio/tv-camara"
              element={<UniqueNewsSystem slug={"municipio-tv-camara"} />}
            />
            <Route
              exact
              path="/municipio/simbolos"
              element={<UniqueNewsSystem slug={"municipio-simbolos"} />}
            />
            {/* Atos administrativos */}
            {/*  3 = Prefeitura 4=Câmara */}
            {TipoPortal == 3 ? (
              <>
                <Route
                  exact
                  path="/informacoes-institucionais/registro-das-competencias"
                  element={<ShowOfficeInfo />}
                />
                {/* informacoes-institucionais -- Prefeitura */}
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/lei-organica"
                  element={
                    <ActsAdministrative
                      name={"Lei Orgânica"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais "
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/codigo-tributario"
                  element={
                    <ActsAdministrative
                      name={"Código Tributário"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais "
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/lei"
                  element={<ActsAdministrative name={"Leis Municipais"} />}
                  route={
                    "/ Informações Institucionais / Leis Municipais-Estaduais "
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/projeto-de-lei"
                  element={
                    <ActsAdministrative
                      name={"Projeto Executivo"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais "
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/decreto"
                  element={
                    <ActsAdministrative
                      name={"Decreto"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais "
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/portarias/nomeacao"
                  element={
                    <ActsAdministrative
                      name={"Portarias de Nomeações"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais / Portaria"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/portarias/exoneracao"
                  element={
                    <ActsAdministrative
                      name={"Portarias de Exonerações"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais / Portaria"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/portarias/outros"
                  element={
                    <ActsAdministrative
                      name={"Portarias"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais / Portaria"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/leis-municipais/estaduais/outros-atos-administrativos"
                  element={
                    <ActsAdministrative
                      name={"Outros Atos Administrativos"}
                      route={
                        "/ Informações Institucionais / Leis Municipais-Estaduais "
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/mural"
                  element={
                    <ActsAdministrative
                      name={"Mural"}
                      route={"/ Informações Institucionais "}
                    />
                  }
                />
                <Route
                  exact
                  path="/informacoes-institucionais/mural/historico"
                  element={<HistoryBienios />}
                />
              </>
            ) : (
              <>
                {/* Vereadores */}
                <Route
                  exact
                  path="/vereadores/registro-das-competencias"
                  element={<ShowOfficeInfo />}
                />
                <Route
                  exact
                  path="/vereadores/populacao-x-vereadores"
                  element={<UniqueNewsSystem slug={"populacao-x-vereadores"} />}
                />
                <Route
                  exact
                  path="/vereadores/termo-de-posse-dos-vereadores"
                  element={
                    <UniqueNewsSystem slug={"termo-de-posse-dos-vereadores"} />
                  }
                />

                {/* legislativo-municipal -- Câmara */}
                <Route
                  exact
                  path="/legislativo-municipal/concurso-publico"
                  element={
                    <ListConcurso routes="/ Legislativo Municipal / Concurso Público " />
                  }
                />

                <Route
                  exact
                  path="/legislativo-municipal/legislacao/lei-organica"
                  element={<ActsAdministrative name={"Lei Orgânica"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/codigo-tributario"
                  element={<ActsAdministrative name={"Código Tributário"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/lei"
                  element={<ActsAdministrative name={"Leis Ordinárias"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/projeto-de-lei"
                  element={<ActsAdministrative name={"Projeto Executivo"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/regimento-interno"
                  element={<ActsAdministrative name={"Regimento Interno"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/decreto"
                  element={<ActsAdministrative name={"Decreto"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/emendas"
                  element={<ActsAdministrative name={"Emendas"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/resolucoes"
                  element={<ActsAdministrative name={"Resoluções"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/portarias/nomeacao"
                  element={
                    <ActsAdministrative name={"Portarias de Nomeações"} />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/portarias/exoneracao"
                  element={
                    <ActsAdministrative name={"Portarias de Exonerações"} />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/portarias/outros"
                  element={<ActsAdministrative name={"Portarias"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao/outros-atos-administrativos"
                  element={
                    <ActsAdministrative name={"Outros Atos Administrativos"} />
                  }
                />

                <Route
                  exact
                  path="/legislativo-municipal/cotas-para-exercicio-da-atividade-parlamentar/verba"
                  element={
                    <ActsAdministrative
                      name={
                        "Cotas para exercício da atividade parlamentar/verba"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/legislacao-relacionada-a-gastos-dos-parlamentares"
                  element={
                    <ActsAdministrative
                      name={"Legislação relacionada a gastos dos parlamentares"}
                    />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/projeto-legislativo"
                  element={<ActsAdministrative name={"Projeto Legislativo"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/pauta-das-comissoes-e-das-sessoes-do-plenario"
                  element={
                    <ActsAdministrative
                      name={"Pauta das Comissões e das Sessões do Plenário"}
                    />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/ata-de-sessoes/solene"
                  element={<ActsAdministrative name={"Solene"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/ata-de-sessoes/ordinaria"
                  element={<ActsAdministrative name={"Ordinária"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/ata-de-sessoes/extraordinaria"
                  element={<ActsAdministrative name={"Extraordinária"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/votacao-nominais-e/ou-unanimes"
                  element={
                    <ActsAdministrative
                      name={"Votações Nominais dos Projetos de Leis"}
                    />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/presencas-e-ausencias-dos-parlamentares"
                  element={
                    <ActsAdministrative
                      name={"Controle de Frequência dos Vereadores"}
                    />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/julgamento-das-contas-do-governador/prefeito"
                  element={
                    <ActsAdministrative name={"Julgamento das Contas Anuais"} />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/resultado-inspecoes-prestacoes-e-tomadas-de-contas"
                  element={
                    <ActsAdministrative
                      name={
                        "Resultados de inspeções, auditorias, prestações e tomadas de contas"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/legislativo-municipal/mural"
                  element={<ActsAdministrative name={"Mural"} />}
                />
                <Route
                  exact
                  path="/legislativo-municipal/mural/historico"
                  element={<HistoryBienios />}
                />
              </>
            )}
            {!RoutesDaily ? (
              <></>
            ) : (
              <>
                {/* Diário Oficial */}
                <Route
                  exact
                  path="/diario-oficial/lei-de-criacao-do-diario"
                  element={
                    <ActsAdministrative
                      name={
                        "Lei de Criação do Diário Oficial Eletrônico do Município"
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/diario-oficial/ato-de-nomeacao-do-responsavel"
                  element={
                    <ActsAdministrative
                      name={
                        "Ato de Nomeação de Pessoa Responsável pelas Publicações do Diário Oficial do Município"
                      }
                    />
                  }
                />
                {/* Rotas Diário Oficial com caminho partindo da transparência */}
                <Route
                  exact
                  path="/transparencia/lei-de-criacao-do-diario"
                  element={
                    <ActsAdministrative
                      name={
                        "Lei de Criação do Diário Oficial Eletrônico do Município"
                      }
                      route={"/ Transparência"}
                    />
                  }
                />
                <Route
                  exact
                  path="/transparencia/ato-de-nomeacao-do-responsavel"
                  element={
                    <ActsAdministrative
                      name={
                        "Ato de Nomeação de Pessoa Responsável pelas Publicações do Diário Oficial do Município"
                      }
                      route={"/ Transparência"}
                    />
                  }
                />
              </>
            )}
            {/* Pessoal */}
            <Route
              exact
              path="/pessoal/estatuto-dos-servidores"
              element={<ActsAdministrative name={"Estatuto dos Servidores"} />}
            />
            <Route
              exact
              path="/pessoal/relacao-de-servidores-inativos"
              element={
                <ActsAdministrative name={"Relação de servidores inativos"} />
              }
            />
            <Route
              exact
              path="/pessoal/relacao-de-servidores-ocupantes-de-cargo-em-comissao"
              element={
                <ActsAdministrative
                  name={"Relação de servidores ocupantes de cargo em comissão"}
                />
              }
            />
            <Route
              exact
              path="/pessoal/relacao-de-servidores-cedidos-para-outros-orgaos-publicos"
              element={
                <ActsAdministrative
                  name={
                    "Relação de servidores cedidos para outros Órgãos Públicos"
                  }
                />
              }
            />
            <Route
              exact
              path="/pessoal/relacao-nominal-dos-estagiarios"
              element={
                <ActsAdministrative name={"Relação nominal dos estagiários"} />
              }
            />
            <Route
              exact
              path="/pessoal/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao"
              element={
                <ActsAdministrative
                  name={
                    "Relação nominal dos agentes públicos contratados temporariamente e contratados de terceirização"
                  }
                />
              }
            />
            <Route
              exact
              path="/pessoal/tabela-remuneratoria-dos-cargos-e-funcoes"
              element={
                <ActsAdministrative
                  name={"Tabela remuneratória dos cargos e funções"}
                />
              }
            />
            <Route
              exact
              path="/pessoal/remuneracao-nominal-de-cada-servidor"
              element={
                <ActsAdministrative name={"Relação nominal de cada servidor"} />
              }
            />
            {/* Diárias */}
            <Route
              exact
              path="/diarias/tabela-de-valores-das-diarias"
              element={<ActsAdministrative name={"Diárias e Legislação"} />}
            />
            <Route
              exact
              path="/diarias/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais"
              element={
                <ActsAdministrative
                  name={
                    "Relação das diárias, dentro e fora Estado, e fora do País"
                  }
                />
              }
            />
            {/* Cidadão */}
            <Route
              exact
              path="/cidadao-e-sic/e-sic-rol-de-informacoes(12meses)"
              element={
                <ActsAdministrative
                  name={
                    "Rol das informações que tenham sido desclassificadas nos últimos 12 (doze) meses"
                  }
                />
              }
            />
            <Route
              exact
              path="/cidadao-e-sic/e-sic-rol-de-documentos(12meses)"
              element={
                <ActsAdministrative
                  name={
                    "Rol de documentos classificados em cada grau de sigilo, com identificação para referência futura"
                  }
                />
              }
            />
            {/* Licitações e contratos  */}
            <Route
              exact
              path="/licitacoes-e-contratos/indicacao-do-fiscal-do-contrato"
              element={
                <ActsAdministrative name={"Indicação do Fiscal do Contrato"} />
              }
            />
            <Route
              exact
              path="/licitacoes-e-contratos/detalhes/:search"
              element={<SacopAndLicitacao />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/contratos/detalhes/:search"
              element={<ContratoInfo />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/avisos"
              element={<ListAllSacop name={"Avisos"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/edital"
              element={<ListAllSacop name={"Edital e Licitações"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/dispensas"
              element={<ListAllSacop name={"Dispensas"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/obras-publicas"
              element={<ListAllSacop name={"Obras Públicas"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/inexigibilidade"
              element={<ListAllSacop name={"Inexigibilidade"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/pregao-eletronico"
              element={<ListAllSacop name={"Pregão Eletrônico"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/ata-de-adesao"
              element={<ListAllSacop name={"Adesão de Ata - SRP"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/resultado-do-certame"
              element={<ListAllSacop name={"Resultado do Certame"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/convenio"
              element={<ListAllSacop name={"Convênio"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/transferencias-recebidas"
              element={
                <ListCardSacopAndFilters name={"Transferencias Recebidas"} />
              }
            />
            <Route
              exact
              path="/licitacoes-e-contratos/termo-de-aditivo"
              element={<ListAllSacop name={"Termo de Aditivo"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/desertas-fracassadas"
              element={<ListAllSacop name={"Desertas ou Fracassadas"} />}
            />
            <Route
              exact
              path="/licitacoes-e-contratos/contrato"
              element={<ListAllTypeContrato name={"Contratos"} />}
            />
            {/* Rotas De Licitação e contratos Com caminho partindo da transparência */}
            {/* Rotas Atos Com caminho partindo da transparência */}
            {/* Paginas Buttons transparencia */}
            <Route
              exact
              path="/transparencia/ jifgjfji"
              element={<ActsAdministrative name={""} />}
            />
            <Route
              exact
              path="/transparencia/em-instrumentos-da-gestao-fiscal-e-de-planejamento"
              element={
                <ActsAdministrative
                  name={"Em Instrumentos da Gestão Fiscal e de Planejamento"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/programas-acoes-projetos-e-obras"
              element={
                <ActsAdministrative
                  name={"Programas, Ações, Projetos e Obras"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/indicacao-do-fiscal-do-contrato"
              element={
                <ActsAdministrative
                  name={"Indicação do Fiscal do Contrato"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/audiencias-publicas"
              element={<ActsAdministrative name={"Audiências Públicas"} />}
            />
            <Route
              exact
              path="/transparencia/parecer-previo"
              element={<ActsAdministrativeSubtype name={"Parecer Prévio"} />}
            />
            <Route
              exact
              path="/transparencia/parecer-previo-do-tce-ma"
              element={
                <ActsAdministrativeSubtype name={"Parecer Prévio do TCE-MA"} />
              }
            />
            <Route
              exact
              path="/transparencia/gestao-fiscal"
              element={<ActsAdministrativeSubtype name={"Gestão Fiscal"} />}
            />
            {/* Pessoal */}
            <Route
              exact
              path="/transparencia/estatuto-dos-servidores"
              element={
                <ActsAdministrative
                  name={"Estatuto dos Servidores"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/relacao-de-servidores-inativos"
              element={
                <ActsAdministrative
                  name={"Relação de servidores inativos"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/relacao-de-servidores-ocupantes-de-cargo-em-comissao"
              element={
                <ActsAdministrative
                  name={"Relação de servidores ocupantes de cargo em comissão"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/relacao-de-servidores-cedidos-para-outros-orgaos-publicos"
              element={
                <ActsAdministrative
                  name={
                    "Relação de servidores cedidos para outros Órgãos Públicos"
                  }
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/relacao-nominal-dos-estagiarios"
              element={
                <ActsAdministrative
                  name={"Relação nominal dos estagiários"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/relacao-nominal-dos-agentes-publicos-contratados-temporariamente-e-contratados-de-terceirizacao"
              element={
                <ActsAdministrative
                  name={
                    "Relação nominal dos agentes públicos contratados temporariamente e contratados de terceirização"
                  }
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/tabela-remuneratoria-dos-cargos-e-funcoes"
              element={
                <ActsAdministrative
                  name={"Tabela remuneratória dos cargos e funções"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/remuneracao-nominal-de-cada-servidor"
              element={
                <ActsAdministrative
                  name={"Relação nominal de cada servidor"}
                  route={"/ Transparência"}
                />
              }
            />
            {/* Diárias */}
            <Route
              exact
              path="/transparencia/tabela-de-valores-das-diarias"
              element={
                <ActsAdministrative
                  name={"Diárias e Legislação"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/relacao-das-diarias-dentro-e-fora-estado-e-fora-do-pais"
              element={
                <ActsAdministrative
                  name={
                    "Relação das diárias, dentro e fora Estado, e fora do País"
                  }
                  route={"/ Transparência"}
                />
              }
            />
            {/* Cidadão */}
            <Route
              exact
              path="/transparencia/e-sic-rol-de-informacoes(12meses)"
              element={
                <ActsAdministrative
                  name={
                    "Rol das informações que tenham sido desclassificadas nos últimos 12 (doze) meses"
                  }
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/e-sic-rol-de-documentos(12meses)"
              element={
                <ActsAdministrative
                  name={
                    "Rol de documentos classificados em cada grau de sigilo, com identificação para referência futura"
                  }
                  route={"/ Transparência"}
                />
              }
            />
            {/* Outros */}
            <Route
              exact
              path="/transparencia/concurso-publico"
              element={
                <ListConcurso routes="/ Transparência  / Concurso Público " />
              }
            />
            {/* Sala Empreendedor */}
            <Route
              exact
              path="/transparencia/sala-empreendedor"
              element={
                <SalaEmpreendedor route="/ Transparência  / Sala Empreendedor " />
              }
            />
            <Route
              exact
              path="/transparencia/sala-empreendedor/mei"
              element={
                <SalaEmpreendedorMEI route="/ Transparência  / Sala Empreendedor / MEI " />
              }
            />
            <Route
              exact
              path="/transparencia/sala-empreendedor/sala-digital"
              element={
                <SalaEmpreendedorSERVICES route="/ Transparência  / Sala Empreendedor / Sala Digital " />
              }
            />
            <Route
              exact
              path="/transparencia/sala-empreendedor/ferramentas-de-gestao"
              element={
                <SalaEmpreendedorFERRAMENTAS route="/ Transparência  / Sala Empreendedor / Ferramentas de Gestão" />
              }
            />
            <Route
              exact
              path="/transparencia/lei-organica"
              element={
                <ActsAdministrative
                  name={"Lei Orgânica"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/codigo-tributario"
              element={
                <ActsAdministrative
                  name={"Código Tributário"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/lei"
              element={
                <ActsAdministrative
                  name={"Leis Ordinárias"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/projeto-de-lei"
              element={
                <ActsAdministrative
                  name={"Projeto Executivo"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/regimento-interno"
              element={
                <ActsAdministrative
                  name={"Regimento Interno"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/decreto"
              element={
                <ActsAdministrative
                  name={"Decreto"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/emendas"
              element={
                <ActsAdministrative
                  name={"Emendas"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/resolucoes"
              element={
                <ActsAdministrative
                  name={"Resoluções"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/portarias/nomeacao"
              element={
                <ActsAdministrative
                  name={"Portarias de Nomeações"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/portarias/exoneracao"
              element={
                <ActsAdministrative
                  name={"Portarias de Exonerações"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/portarias/outros"
              element={
                <ActsAdministrative
                  name={"Portarias"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/outros-atos-administrativos"
              element={
                <ActsAdministrative
                  name={"Outros Atos Administrativos"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/cotas-para-exercicio-da-atividade-parlamentar/verba"
              element={
                <ActsAdministrative
                  name={"Cotas para exercício da atividade parlamentar/verba"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/legislacao-relacionada-a-gastos-dos-parlamentares"
              element={
                <ActsAdministrative
                  name={"Legislação relacionada a gastos dos parlamentares"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/projeto-legislativo"
              element={
                <ActsAdministrative
                  name={"Projeto Legislativo"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/pauta-das-comissoes-e-das-sessoes-do-plenario"
              element={
                <ActsAdministrative
                  name={"Pauta das Comissões e das Sessões do Plenário"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/ata-de-sessoes/solene"
              element={
                <ActsAdministrative name={"Solene"} route={"/ Transparência"} />
              }
            />
            <Route
              exact
              path="/transparencia/ata-de-sessoes/ordinaria"
              element={
                <ActsAdministrative
                  name={"Ordinária"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/ata-de-sessoes/extraordinaria"
              element={
                <ActsAdministrative
                  name={"Extraordinária"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/votacao-nominais-e/ou-unanimes"
              element={
                <ActsAdministrative
                  name={"Votações Nominais dos Projetos de Leis"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/presencas-e-ausencias-dos-parlamentares"
              element={
                <ActsAdministrative
                  name={"Controle de Frequência dos Vereadores"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/julgamento-das-contas-do-governador/prefeito"
              element={
                <ActsAdministrative
                  name={"Julgamento das Contas Anuais"}
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/resultado-inspecoes-prestacoes-e-tomadas-de-contas"
              element={
                <ActsAdministrative
                  name={
                    "Resultados de inspeções, auditorias, prestações e tomadas de contas"
                  }
                  route={"/ Transparência"}
                />
              }
            />
            <Route
              exact
              path="/transparencia/mural"
              element={
                <ActsAdministrative name={"Mural"} route={"/ Transparência"} />
              }
            />
            {/* Página de controle de rota (se a rota não existir) */}
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </AuthProvicer>
      </BrowserRouter>
    </>
  );
}
